<template>
  <CCard>
    <CCardHeader>
      <slot name="header"> <i :class="icon"></i>&nbsp; {{ caption }} </slot>      
    </CCardHeader>
    <CCardBody>
      <CDataTableDev
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
        :caption="vue.texto"
        
        
      >
        <template #status="{ item }">
          <td>
            <CBadge :color="getBadge(item.status)">{{ item.status }}</CBadge>
          </td>
        </template>
      </CDataTableDev>
    </CCardBody>
  </CCard>
</template>

<script>
import CDataTableDev from './cDatatable.vue'
export default {
  name: "Table",
  data(){
    return{
      vue: {
        texto: true,
        btn_pass: false,
    
      
      }
    }
  },
  components:{
    CDataTableDev
  },
  props: {
    items: Array,
    fields: {
      type: Array,
      default:[]
    },
    caption: {
      type: String,
      default: "Table",
    },
    icon: {
      type: String,
      default: "Table",
    },
    btn_name: {
      type: String,
      default: "Table",
    },
    showhide_buttons: Array,
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  created(){
    this.ValidarItem(this.btn_name);
  
  },
  methods: {
    getBadge(status) {
      return status === "Active"
        ? "success"
        : status === "Inactive"
        ? "secondary"
        : status === "Pending"
        ? "warning"
        : status === "Banned"
        ? "danger"
        : "primary";
    },
    ShowHide(status){
      return status === "ocultar"
        ? "display:none"
        : "display:block";
    },
    ValidarItem(status){
      if (status === "ocultar") {
        this.vue.texto = false;
      } else {
        this.vue.texto = true;
      }
    },
    Verif_ShowHide(estados){
      if(estados === undefined){
      }
      else{
        estados.forEach(element => {
            this.vue.btn_pass = this.estados(element.btn_pass);
            this.vue.btn_edit = this.estados(element.btn_edit);
            this.vue.btn_del = this.estados(element.btn_del);
        });
      }
    },
    estados(estado){
      if(estado==1) return true;
      return false;
    },
    action_modal: function(){
      this.$emit('show_modal',true,'store');
    },
    action_pass: function(item){
      this.$emit('action_pass',item);
    },
    action_edit: function(item){
        this.$emit('action_edit',item);
    },
    action_delete: function(item){
      this.$emit('action_delete',item);
    }
  },
};
</script>
