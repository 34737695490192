<template>
  <div>
    <CCard>
      <CCardHeader>
        <CCol>
          <label class="radio-label">Elija lo que desea hacer: </label>
          <label class="radio-input" for="registro">
            <input
              id="registro"
              type="radio"
              value="1"
              v-model="visit.type"
              @change="onChange($event)"
            />
            Registrar Visitas
          </label>
          <label class="radio-input" for="programar">
            <input
              id="programar"
              type="radio"
              value="2"
              v-model="visit.type"
              @change="onChange($event)"
              checked
            />
            Programar Visitas
          </label>
          <label class="radio-input" for="pendiente">
            <input
              id="pendiente"
              type="radio"
              value="3"
              v-model="visit.type"
              @change="onChange($event)"
              checked
            />
            Visitas pendientes
          </label>
        </CCol>
      </CCardHeader>
      <CCardBody>
        <CRow v-if="visit.type == 3">
          <CCol sm="6">
            <cSelectForm
              label="Visitas pendientes"
              :options="data_visit_pending"
              placeholder="Escoja una visita"
              :value.sync="pending_select"
              @change="mtd_select_pending"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="2">
            <CInput label="Fecha de visita" type="date" v-model="visit.date" />
          </CCol>
          <CCol sm="2">
            <CInput
              label="Hora de salida"
              type="time"
              placeholder="00:00:00"
              v-model="visit.departure_time"
            />
          </CCol>
          <CCol sm="2">
            <CInput
              label="Hora de llegada"
              type="time"
              :disabled="isDisabled"
              placeholder="00:00:00"
              v-model="visit.arrival_time"
            />
          </CCol>
          <CCol sm="6">
            <cSelectForm
              label="Seleccione un proyecto"
              :options="data_project"
              placeholder="Escoja un asesor"
              :value.sync="visit.project_id"
            />
          </CCol>

          <CCol sm="4">
            <cSelectForm
              label="Seleccione un asesor"
              :options="data_user"
              placeholder="Escoja un asesor"
              :value.sync="visit.user_id"
            />
          </CCol>
          <CCol sm="4">
            <cSelectForm
              label="Seleccione un cliente"
              :options="data_client"
              placeholder="Escoja un cliente"
              :value.sync="visit.client_id"
            />
          </CCol>
          <CCol sm="3">
            <cSelectForm
              label="Seleccione un conductor"
              :options="data_driver"
              placeholder="Escoja un conductor"
              :disabled="isDisabled"
              :value.sync="visit.driver_id"
            />
          </CCol>
          <CCol sm="3">
            <CInput
              label="Precio movilidad"
              placeholder="Precio de movilidad"
              v-model="visit.mobility_price"
              :disabled="isDisabled"
              v-on:keypress="isNumber(event)"
            />
          </CCol>
          <CCol sm="4">
            <label class="radio-label">Ejecución de venta: </label>

            <input
              id="radio1"
              type="radio"
              value="1"
              placeholder="Observaciones de visita"
              :disabled="isDisabled"
              v-model="visit.sale"
            /><label class="radio-input" for="radio1">Sí </label>

            <input
              id="radio2"
              type="radio"
              value="2"
              placeholder="Observaciones de visita"
              :disabled="isDisabled"
              v-model="visit.sale"
            /><label class="radio-input" for="radio2">No </label>
          </CCol>
          <CCol sm="8">
            <CInput
              label="Observaciones"
              placeholder="Observaciones de visita"
              :disabled="isDisabled"
              v-model="visit.observations"
            />
          </CCol>
          <CCol sm="3" v-if="visit.type == 1 || visit.type == 2">
            <label for="btn">&nbsp;</label>
            <CButton
              class="form-control btn-save"
              :disabled="button"
              block
              color="primary"
              @click="mtd_save_visit()"
              ><i class="fas fa-save"></i>&nbsp;Registrar</CButton
            >
          </CCol>
          <CCol sm="3" v-if="visit.type == 3">
            <CButton
              class="form-control btn-save"
              :disabled="button"
              block
              color="primary"
              @click="mtd_edit_visit()"
              ><i class="fas fa-save"></i>&nbsp;Editar</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard style="display: none">
      <CRow>
        <CCol sm="12">
          <CTableWrapper
            :items="data"
            :fields="fields"
            hover
            striped
            border
            small
            fixed
            caption="Lista de Visitas"
            icon="fas fa-file-alt"
            :size="'md'"
          />
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/TableVisits.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper, cSelectForm },
  data() {
    return {
      prefix: "visit",
      vue: {
        exp: false,
        texto: "",
        showhide: Array, // dato para los estados de los botones
      },
      fields: [
        "Id",
        "Fecha",
        "Hora_Salida",
        "Asesor",
        "Cliente",
        "Hora_llegada",
        "Movilidad",
        "Costo_Movilidad",
        "Proyecto",
        "Ejecucion_venta",
        "Observaciones",
      ],
      data: [],
      data_user: [],
      data_client: [],
      data_driver: [],
      data_project: [],
      data_visit_pending: [],

      pending_select: null,
      form_add_user: {
        id: "",
        name: "",
        last_name: "",
      },
      form_add_driver: {
        id: "",
        names: "",
        last_names: "",
      },
      form_add_client: {
        id: "",
        name: "",
        last_names: "",
        phone: "",
        email: "",
      },

      visit: {
        id: "",
        date: "",
        user_id: "",
        client_id: "",
        departure_time: "",
        arrival_time: "",
        project_id: "",
        driver_id: "",
        mobility_price: "",
        sale: "",
        type: 1,
        observations: "",
      },
    };
  },

  computed: {
    button() {
      if (this.visit.type == "1") {
        if (
          this.visit.date != "" &&
          this.visit.user_id != "" &&
          this.visit.client_id != "" &&
          this.visit.project_id != "" &&
          this.visit.driver_id != "" &&
          this.visit.departure_time != "" &&
          this.visit.arrival_time != "" &&
          this.visit.mobility_price != "" &&
          this.visit.sale != ""
        )
          return false;
        return true;
      } else {
        if (
          this.visit.date != "" &&
          this.visit.departure_time != "" &&
          this.visit.user_id != "" &&
          this.visit.client_id != "" &&
          this.visit.project_id != ""
        )
          return false;
        return true;
      }
    },
    cp_button_busqueda: function () {
      return false;
    },
    isDisabled: function () {
      if (this.visit.type != "2") {
        return !this.visit.type;
      } else {
        return this.visit.type;
      }
    },
  },

  created() {
    this.mtd_getdata();
    // this.mtd_getdata_client();
    // this.mtd_getdata_driver();
    // this.mtd_getdata_user();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
        params: {
          idUser: this.$store.getters.get__user_id,
        },
      })
        .then((response) => {
          this.data = response.data;
          this.data_user = response.data_user;
          this.data_client = response.data_client;
          this.data_driver = response.data_driver;
          this.data_project = response.data_project;
          this.visit.date = response.date;
          this.data_visit_pending = response.pending;
        })
        .catch((errors) => {});
    },

    mtd_save_visit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/" + "store",
        token: this.$store.getters.get__token,
        params: this.visit,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.visit.action == "store") {
            if (response[0].Id == 1) {
              this.mtd_getdata();
            } else {
              this.data.push(response[0]);
            }
          }
          this.visit = {
            id: null,
            date: null,
            user_id: null,
            client_id: null,
            departure_time: null,
            arrival_time: null,
            driver_id: null,
            mobility_price: null,
            project_id: null,
            sale: null,
            observations: null,
          };

          bus.$emit("alert", {
            color: color,
            message: "REGISTRADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_edit_visit: function () {
      let color = "success";
      let visita_id;
      visita_id = this.pending_select;
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          visita_id +
          "/update",
        token: this.$store.getters.get__token,
        params: this.visit,
      })
        .then((response) => {
          this.data_visit_pending.forEach((element) => {
            if (element.id == this.pending_select) {
              this.mtd_getdata();
            }
            this.visit = {
              id: "",
              date: "",
              user_id: "",
              client_id: "",
              departure_time: "",
              arrival_time: "",
              driver_id: "",
              mobility_price: "",
              project_id: "",
              sale: "",
              observations: "",
            };
            bus.$emit("alert", {
              color: color,
              message: "EDITADO CORRECTAMENTE",
            });
          });

          this.visit.project_id = 0;
          this.visit.user_id = 0;
          this.visit.client_id = 0;
          this.visit.driver_id = 0;
        })

        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },

    mtd_select_pending: function () {
      this.data_visit_pending.forEach((element) => {
        if (element.value == this.pending_select) {
          this.visit.date = element.all.date;
          this.visit.user_id = element.all.user_id;
          this.visit.client_id = element.all.client_id;
          this.visit.departure_time = element.all.departure_time;
          this.visit.arrival_time = element.all.arrival_time;
          this.visit.project_id = element.all.project_id;
          this.visit.driver_id = element.all.driver_id;
          this.visit.mobility_price = element.all.mobility_price;
          this.visit.sale = element.all.state;
          this.visit.observations = element.all.observation;
        }
      });
    },
    onChange: function (event) {
      this.pending_select = null;
      this.visit = {
        id: null,
        date: null,
        user_id: null,
        client_id: null,
        departure_time: null,
        arrival_time: null,
        project_id: null,
        driver_id: null,
        mobility_price: null,
        sale: null,
        type: event.target.value,
        observations: null,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-label {
  position: relative;
  margin-bottom: 5px;
  padding: 5px;
  display: block;
}
.radio-input {
  margin-bottom: 5px;
  padding-left: 2px;
  padding-right: 15px;
}

.btn-save {
  background-color: #321fdb;
  color: white;
}
.btn-save:disabled {
  background-color: #adc7ec;
  color: #90add7;
  border: 1px solid #999999;
}
.btn-save:enabled:hover {
  border: 1px solid #4887e1;
  background-color: #4887e1;
  color: #ffffff;
  padding: 5px 10px;
}
</style>